import { useEffect, useRef, useState, useCallback } from "react";
import Image from "next/image";

const SelectInput = (props: any) => {
  const {
    cls,
    placeholder,
    width,
    options,
    initialSelected,
    height,
    errors,
    onSelect,
    reset,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  // const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [dropdownValue, setDropdownValue] = useState(placeholder);
  const initialEffectRan = useRef(false);

  useEffect(() => {
    if (reset) {
      setDropdownValue(placeholder);
      setIsSelected(false);
    }
  }, [reset, placeholder]);

  const handleItemClick = useCallback(
    (value: any) => {
      setDropdownValue(value);
      setIsSelected(true);
      onSelect(value);
      setIsOpen(false);
    },
    [onSelect]
  );

  useEffect(() => {
    if (initialSelected && !initialEffectRan.current) {
      initialSelected.map((item: any) => {
        handleItemClick(item);
      });
      initialEffectRan.current = true;
    }
  }, [initialSelected, handleItemClick]);

  const handleMouseLeave = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  return (
    <>
      <div
        className={`relative inline-block w-full z-50`}
        onMouseLeave={handleMouseLeave}
      >
        <div
          onClick={() => setIsOpen(!isOpen)}
          className={`w-[${width}] flex bg-white border border-solid  px-4 py-1.5 items-center justify-between hover:cursor-pointer ${
            height ? `h-[${height}]` : "h-[50px]"
          } ${cls} ${errors ? "border-red" : "border-[#C7CDCE]"} ${
            isOpen ? "border-b-0" : ""
          }
            `}
        >
          <span
            className={`bdy-txt-6 overflow-hidden whitespace-nowrap overflow-ellipsis  ${
              isSelected ? "text-black" : "text-[#9E9E9E]"
            }`}
          >
            {dropdownValue}{" "}
          </span>

          <Image
            width={15}
            height={20}
            alt="arrow"
            className="cursor-pointer"
            src="/assets/icons/arrow-down.svg"
          ></Image>
        </div>
        {isOpen && (
          <div
            className={`${
              isOpen ? "block" : "hidden"
            } absolute bg-white border z-10 border-solid border-[#C7CDCE] max-h-[400px] overflow-y-auto w-[${width}]  ${
              isOpen ? "border-t-0" : ""
            }`}
          >
            <ul>
              {options.map((option: any, index: number) => (
                <li
                  key={index}
                  className="p-2 pl-4 cursor-pointer"
                  onClick={() => handleItemClick(option.value)}
                >
                  <span className="bdy-txt-6 text-[#9E9E9E]">
                    {option.label}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};
export default SelectInput;
