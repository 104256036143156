"use client";
// import { countriesLOV } from "@/data/money-transfer";
import Image from "next/image";
import { useEffect, useRef, useState, useCallback } from "react";
import SelectInput from "../Form/SelectInput";

import { commonFetch } from "@/utils/commonFetch";
const Calculator = () => {

  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [amount, setAmount] = useState(0);
  const [showFees, setShowFees] = useState(false);
  const popupRef = useRef<HTMLDivElement | null>(null);

  const [options, setOptions] = useState([]);
  const [countries, setCountries] = useState<any>([]);

  useEffect(() => {
    const fetchCountryFees = async () => {
      const repsonse = await commonFetch.get("country-fees")
      setCountries(repsonse)
      setOptions(
        repsonse?.map((item: any) => ({
          label: item.countryName,
          value: item.countryName,
        }))
      )
    }
    fetchCountryFees();
  }, [])

  const handleSelect = useCallback(
    (event: any) => {
      const selectedCountry = countries?.find(
        (country: any) => country.countryName === event
      );
      setSelectedCountry(selectedCountry);
    },
    [countries]
  );

  const onSubmit = () => {
    setShowFees(true);
  };

  const calculateFees = () => {
    let fee = 0;
    selectedCountry?.FeesRow?.forEach((item: any) => {
      if (amount >= item.from && amount <= item.to) {
        fee = item.fees;
      }
    });
    return fee;
  };

  const onChangeAmount = (event: any) => {
    setAmount(event.target.value);
    //console.log("Amount changed:", event.target.value);
  };

  return (
    <div className="bg-red p-12 w-screen md:w-[400px] h-[300px] relative">
      <div className="traingle border-l-[25px] border-r-[25px] border-t-[15px] border-t-[#fff] -left-[18px] top-[60px] -rotate-90"></div>
      <div className="pb-4">
        <label className="text-white font-avBlack text-[18px] leading-[26px] lg:text-[24px] lg:leading-[35px]">
          Send
        </label>
        <div className="flex items-center">
          <input
            type="number"
            min={1}
            className="p-3 h-[50px] w-3/4 border-[#C7CDCE] border-2 border-r-0 focus:outline-none"
            placeholder="Enter amount"
            onBlur={(e) => onChangeAmount(e)}
          />
          <div className="p-3 h-[50px] bg-white w-[30%] border-[#C7CDCE] border-2 border-l-0 focus:outline-none">
            USD
          </div>
        </div>
      </div>
      <div className="pb-4">
        <label className="text-white font-avBlack text-[18px] leading-[26px] lg:text-[24px] lg:leading-[35px]">
          Receiver Country
        </label>
        <label className="block">
          <SelectInput
            options={options}
            onSelect={handleSelect}
            width={"100%"}
            placeholder={"Select country"}
          />
        </label>
      </div>
      <div>
        <button className="btn-third text-white float-right" onClick={onSubmit}>
          <Image
            src="/assets/icons/arrow-right.svg"
            className="mb-[12px] h-[13px] w-[13px]"
            alt="white Arrow Icon"
            width={300}
            height={300}
          />
          <span className="h-[36px] hover:border-b-[4px] hover:border-red">
            Estimate fees
          </span>
        </button>
      </div>

      {showFees && (
        <div
          ref={popupRef}
          className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[40%] h-[40%] bg-white border p-8 overflow-y-auto z-[100]"
        >
          <div>
            <h2 className="text-[24px] font-avBold ">
              Amount: <span className="text-red">{amount}</span>
            </h2>
            <h2 className="text-[24px] font-avBold ">
              Country:{" "}
              <span className="text-red">
                {selectedCountry ? selectedCountry?.countryName : ""}
              </span>
            </h2>
            <h2 className="text-[24px] font-avBold ">
              Fees: <span className="text-red">{calculateFees()}</span>
            </h2>
          </div>
          <div className="md:top-[20px] md:mr-[20px] top-[10px] mr-[10px] right-0 absolute">
            <button
              className="md:p-[10px] p-0"
              onClick={() => setShowFees(false)}
            >
              <Image
                src="/assets/images/footer/close.svg"
                alt="Close"
                width={25}
                height={20}
              />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default Calculator;
